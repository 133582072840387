import { SuiModalService } from 'ng2-semantic-ui';
import * as i0 from "@angular/core";
import * as i1 from "ng2-semantic-ui/dist/modules/modal/services/modal.service";
var ModalService = /** @class */ (function () {
    function ModalService(suiModalService) {
        this.suiModalService = suiModalService;
        this.modals = [];
    }
    ModalService.prototype.open = function (modal) {
        var createdModal = this.suiModalService.open(modal);
        this.modals.push(createdModal);
        return createdModal;
    };
    ModalService.prototype.closeAllModals = function () {
        for (var _i = 0, _a = this.modals; _i < _a.length; _i++) {
            var modal = _a[_i];
            modal.deny(undefined);
        }
    };
    ModalService.ngInjectableDef = i0.defineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.inject(i1.SuiModalService)); }, token: ModalService, providedIn: "root" });
    return ModalService;
}());
export { ModalService };
