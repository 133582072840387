import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

import { Injectable, NgZone } from '@angular/core';

import { compress, decompress } from '../_util/compression';

@Injectable({
	providedIn: 'root'
})
export class SocketService {
	private socket: any;
	private registeredTaggedEvents: any = {};
	private registeredEvents: any = {};

	constructor(private zone: NgZone) { }

	public init(endpointBaseUrl: string, target: string, rejectUnauthorized: boolean = true): Observable<any> {
		return new Observable((observer) => {
			const socketConnectionOpts = {
				forceNew: true,
				transports: ['websocket'],
				rejectUnauthorized: rejectUnauthorized,
				query: {
					target: target,
				},
			};

			const connectionUrl = endpointBaseUrl;
			this.socket = io(connectionUrl, socketConnectionOpts);

			this.socket.on('connect', async () => {
				observer.next(this.socket);
				observer.complete();
			});

			this.socket.on('error', (e) => {
				console.error(e);
				observer.error(e);
				observer.complete();
			});

			this.socket.on('data', (payload) => {
				const { event, tag, data, success } = decompress(payload);
				console.log(`Got ${event} response!`);
				if (tag) {
					if (this.registeredTaggedEvents[tag]) {
						this.registeredTaggedEvents[tag](success, data);
					}
				} else {
					if (this.registeredEvents[event]) {
						this.registeredEvents[event](data);
					}
				}
			});
		});
	}

	public getTargetString(): string {
		if (window.location.host.indexOf('localhost') !== -1 || window.location.host.indexOf('127.0.0.1') !== -1) {
			return 'local';
		}

		const splitHost = window.location.host.split('.');

		return splitHost[0];
	}

	public call(event: string, callback: (Function | undefined) = undefined, ...args: any[]): Observable<any> {
		return new Observable((observer) => {
			const uuidTag = uuidv4();

			this.registerTaggedEvent(uuidTag, (success, data) => {
				if (!success) {
					observer.error(data);
					return;
				}

				if (callback) {
					this.zone.run(() => {
						callback(data);
					});
				}

				this.zone.run(() => {
					observer.next(data);
					observer.complete();
				});
			});

			const payload = { event: event, tag: uuidTag, data: args };
			const compressedPayload = compress(payload);

			console.log(`Sending ${event}...`);
			this.getSocketConnection().emit('data', compressedPayload);
		});
	}

	public registerEvent(event: string, callback: Function) {
		this.registeredEvents[event] = callback;
	}

	public unregisterEvent(event: string) {
		delete this.registeredEvents[event];
	}

	public registerTaggedEvent(tag: string, callback: Function) {
		this.registeredTaggedEvents[tag] = callback;
	}

	public unregisterTaggedEvent(tag: string) {
		delete this.registeredTaggedEvents[tag];
	}

	public getSocketConnection(): any {
		return this.socket;
	}
}
