import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import IUser from 'src/_interfaces/IUser';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import ISession from '@citadel/common-frontend/_interfaces/ISession';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';

import IDeployment from '../_interfaces/IDeployment';
import { ILoginResponse } from '../_interfaces/ILoginResponse';
import { IRedirectedSession } from '../_interfaces/IRedirectedSession';
import Events from '../_util/events';
import { environment } from '../environments/environment';

@Injectable()
export class UserService {
	private user: IUser;
	private session: any;

	constructor(private router: Router, private socketService: SocketService, private cookies: CookieService) { }

	public init(authenticated: boolean): Observable<any> {
		if (authenticated) {
			const cookie = this.getSessionCookie();

			if (!cookie) {
				throw new Error('invalid session');
			}

			this.session = JSON.parse(atob(cookie));
			this.socketService.getSocketConnection().on('reconnect', async () => {
				await this.sendToken().toPromise();
			});

			return this.sendToken();
		}
		return new Observable((observer) => observer.complete());
	}

	public sendToken(): Observable<IUser> {
		return this.socketService.call(
			Events.AUTHENTICATE_TOKEN_EVENT,
			async (user: IUser) => {
				this.user = user;
			},
			this.session.token
		);
	}

	public login(config: any): Observable<ISession<IUser>> {
		return this.socketService.call(
			Events.LOGIN,
			async (response: ILoginResponse) => {
				if (response.type === 'session') {
					const session = response.data as ISession<IUser>;
					this.user = session.user;
					this.setSession(session);
					this.router.navigate(['/dashboard/account']);
				} else {
					const redirection = response.data as IRedirectedSession;
					window.location.href = redirection.targetUrl;
				}
			},
			config
		);
	}

	public register(config: any): Observable<ISession<IUser>> {
		return this.socketService.call(
			Events.REGISTER,
			async (response: ILoginResponse) => {
				const session = response.data as ISession<IUser>;
				this.user = session.user;
				this.setSession(session);
			},
			config
		);
	}

	public listLoginOptions(email: string): Observable<IDeployment[]> {
		return this.socketService.call(Events.LIST_LOGIN_OPTIONS, undefined, { email });
	}

	public completeRegistration(config: any): Observable<IRedirectedSession> {
		return this.socketService.call(Events.COMPLETE_REGISTRATION, undefined, config);
	}

	public resetPassword({ email, password, key }): Observable<ISession<IUser>> {
		return this.socketService.call(
			Events.RESET_PASSWORD,
			async () => {
				this.router.navigate(['/']);
			},
			{ email, password, key }
		);
	}

	public requestPasswordReset(email: string): Observable<any> {
		return this.socketService.call(Events.REQUEST_PASSWORD_RESET, null, email);
	}

	public changePassword(oldPassword: string, newPassword: string): Observable<any> {
		return this.socketService.call(Events.CHANGE_PASSWORD, null, oldPassword, newPassword);
	}

	public async logout() {
		this.clearSession();

		setTimeout(() => {
			this.router.navigate(['/authentication/login']);
		}, 100);
	}

	public getUser(): IUser {
		return this.user;
	}

	public setSession(session: ISession<IUser>): void {
		this.session = session;
		// tslint:disable-next-line:max-line-length
		this.cookies.set(`session`, btoa(JSON.stringify(session)), new Date(session.expires), '/', environment.cookie.url, environment.cookie.secure, <any>environment.cookie.sameSite);
	}

	public clearSession(): void {
		this.user = null;
		this.session = null;
		this.cookies.deleteAll('/', environment.cookie.url);
	}

	public getSession(): ISession<IUser> {
		return this.session;
	}

	public getSessionCookie(): string {
		return this.cookies.get(`session`);
	}
}
